import React from 'react'
import './rps.css'
import RPSModal from './RPSModal'
import LandingNav from '../../../navs/LandingNav'
const RPS = ()=> {
    
	return (
		<div>
			<LandingNav/>
		<RPSModal/>
		</div>
	)
}

export default RPS;